<template>
  <div>
    <div class="row">
      <div class="col-12">
          <div class="table-full-width table-responsive" style="overflow-y: hidden;">
            <table id="tbl-dias" class="display compact">
              <thead>
                <tr>
                   <th class="text-center sm">
                      <small style="font-weight: bold;">Mês</small>
                   </th>  
                    <th v-for="metrica in metrics" class="th-sm text-center">
                      <small style="font-weight: bold;">{{ metrica['nome'] }}</small>
                    </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in newdata" :key="index">
                    <td>{{index}}</td>
                    <td v-for="metrica in metrics">
                      <template v-if="metrica['id'] == 7">
                        <div class="progress position-relative" style="height:1em;">
                          <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" 
                            :style="'width: '+(parseInt(item[1]/item[4]*100))+'%'" :aria-valuenow="10" 
                            aria-valuemin="0" :aria-valuemax="100">
                            <div class="justify-content-center d-flex position-absolute w-100">{{item[4]==0 ? item[4] : parseInt(item[1]/item[4]*100)}}</div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="progress position-relative" style="height:1em;">
                          <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" 
                            :style="'width: '+(item[metrica['id']]/maxdata[metrica['id']])*100+'%'" :aria-valuenow="10" 
                            aria-valuemin="0" :aria-valuemax="maxdata[metrica['id']]">
                            <div class="justify-content-center d-flex position-absolute w-100">{{item[metrica['id']]}}</div>
                          </div>
                        </div>
                      </template>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-3" :class="isRTL ? 'text-right' : 'text-left'">
                <h5 class="card-category">Performance</h5>
                <h2 class="card-title">Mensal</h2>
              </div>
              <div class="col-sm-9">
                <div class="btn-group btn-group-toggle d-flex flex-wrap"
                     :class="isRTL ? 'float-left' : 'float-right'"
                     data-toggle="buttons">
                  <label v-for="option in metrics"
                         :key="option.id"
                         class="btn btn-sm btn-primary btn-simple"
                         :class="{active: bigLineChart.activeIndex === option.id}"
                         :id="option.id">
                    <input type="radio"
                           @click="initBigChart(option.id)"
                           name="options" autocomplete="off"
                           :checked="bigLineChart.activeIndex === option.id">
                    {{option.nome}}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        ref="bigChart"
                        chart-id="big-line-chart"
                        :chart-data="bigLineChart.chartData"
                        :gradient-colors="bigLineChart.gradientColors"
                        :gradient-stops="bigLineChart.gradientStops"
                        :extra-options="bigLineChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import * as chartConfigs from '@/components/Charts/config';
  import TaskList from './Dashboard/TaskList';
  import UserTable from './Dashboard/UserTable';
  import config from '@/config';

  export default {
    components: {
      LineChart,
      BarChart,
      TaskList,
      UserTable
    },
    data() {
      return {
        userdata: {},
        newdata: {},
        maxdata: {},
        metrics: [],
        // metrics: [
        //   {'id':1,'nome':'Primeiro Depósito'},
        //   {'id':2,'nome':'Saques (R$)'},
        //   {'id':3,'nome':'Depósitos (R$)'},
        //   {'id':4,'nome':'Cadastros'},
        //   {'id':5,'nome':'Apostas (R$)'},
        //   {'id':6,'nome':'GGR (R$)'},
        //   {'id':7,'nome':'Conversão Cadastro FTD (%)'},
        //   {'id':8,'nome':'FTDs total (R$)'},
        //   {'id':9,'nome':'FTDs qualificados'},
        //   {'id':10,'nome':'Apostas Bonus (R$)'},
        // ],
        bigLineChart: {
          // allData: [
          // ],
          activeIndex: 0,
          chartData: {
            datasets: [{ }],
            // labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
            labels: [],
          },
          extraOptions: chartConfigs.purpleChartOptions,
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
          categories: []
        },
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      // bigLineChartCategories() {
      //   return this.$t('dashboard.chartCategories');
      // }
    },
    methods: {
      initBigChart(index) {
        let chartData = {
          datasets: [{
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // data: this.bigLineChart.allData[index]
            data: this.userdata[index] ? this.userdata[index].data : []
          }],
          // labels: ['JAN', 'FEV', 'MAR', 'Abr', 'MAi', 'JUN', 'JUL', 'AGo', 'SET', 'OUT', 'NOV', 'DEZ'],
          labels: this.userdata[index] ? this.userdata[index].label : ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
        }
        this.$refs.bigChart.updateGradients(chartData);
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      },
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.AUTH_USER = null
        }
      }
    },
    mounted() {
      var vm = this

      this.$http
      .get(this.$API+'metricas')
      .then(response => {
        this.metrics = response.data
      })
      .catch(function (error) {
            console.error(error)
      });   

      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'ar';
        this.$rtl.enableRTL();
      }
      
      let user = this.getUsuario()
      if (user.id) {
        var data = { 'id' : user.id }
        this.$http
        .post(this.$API+'dashboard/mensal', data)
        .then(response => {
          this.userdata = JSON.parse(JSON.stringify(response.data))
          Object.keys(this.userdata).sort().reverse().forEach(function(metrica, i) {
            vm.maxdata[metrica] = 0;
            for (let j = vm.userdata[metrica]['label'].length-1; j >=0; j--) {
              let dia = vm.userdata[metrica]['label'][j];
              let valor = vm.userdata[metrica]['data'][j];
              if (vm.newdata[dia] == null) {
                vm.newdata[dia] = {}
              }
              if (valor > vm.maxdata[metrica]) {
                vm.maxdata[metrica] = valor
              }
              vm.newdata[dia][metrica] = valor
            }
          })

          this.initBigChart(1);
          
          $(document).ready( function () {
              $('#tbl-dias').DataTable({
                ordering: false,
                lengthMenu: [30, 50, 100, 500],
                "columnDefs": [
                  { "width": "10%", "targets": 0 },
                  { "width": "9%", "targets": 1 },
                  { "width": "9%", "targets": 2 },
                  { "width": "9%", "targets": 3 },
                  { "width": "9%", "targets": 4 },
                  { "width": "9%", "targets": 5 },
                  { "width": "9%", "targets": 6 },
                  { "width": "9%", "targets": 7 },
                  { "width": "9%", "targets": 8 },
                  { "width": "9%", "targets": 9 },
                  { "width": "9%", "targets": 10 },
                ],
                searching: false,
                "language": {
                  "emptyTable": "Nenhum registro disponível na tabela",
                  "info":           "Mostrando _START_ até _END_ de _TOTAL_ registros",
                  "infoEmpty":      "Mostrando 0 até 0 de 0 registros",
                  "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
                  // "infoPostFix":    "",
                  // "thousands":      ",",
                  "lengthMenu":     "Mostrar _MENU_ registros",
                  "loadingRecords": "Carregando...",
                  // "processing":     "",
                  "search":         "Busca:",
                  "zeroRecords":    "Nenhum registro encontrado.",
                  "paginate": {
                      "first":      "Primeiro",
                      "last":       "Último",
                      "next":       "Próximo",
                      "previous":   "Anterior"
                  }
                },
              });
          });
        })
        .catch(function (error) {
              console.error(error)
        });   
      }
    },
    beforeDestroy() {
      if (this.$rtl.isRTL) {
        this.i18n.locale = 'en';
        this.$rtl.disableRTL();
      }
    }
  };
</script>
<style>
</style>
