<template>
  <div>
    <div class="row">
      <div class="col-12">
          <div class="table-full-width table-responsive" style="overflow-y: hidden; overflow-x: hidden;">
            <table id="tbl-guru" class="display compact">
              <thead>
                <tr>
                   <th class="sm" v-for="item in columns">
                      <small style="font-weight: bold;">{{item['label']}}</small>
                   </th>  
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in userdata" :key="index">
                    <td v-for="col in columns">
                      {{ item[col['field']] }}
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <vue-good-table :columns="columns" :rows="userdata" :search-options="{enabled: true, placeholder: 'Buscar...'}" />
      </div>
    </div> -->
  </div>
</template>
<script>
  import config from '@/config';
  

  const MONTHS = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]


  export default {
    data() {
      return {
        columns: [
          { label: 'Player ID', field: 'Player ID'},
          { label: 'Data Cadastro', field: 'dia_cadastro'},
          { label: 'Data Depósito', field: 'dia_deposito'},
          { label: 'Valor', field: 'value'},
        ],
        userdata: {},
        searchOptions: {
              enabled: true,
              placeholder: 'Buscar...',
        },
      }
    },
    methods: {
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.AUTH_USER = null
        }
      },
    },
    mounted() {
      let user = this.getUsuario()
      if (user && user.id) {
        var data = { 'id' : user.id }
        // var data = { 'id' : 7340707 } 
        this.$http
        .post(this.$API+'dashboard/table', data)
        .then(response => {
          this.userdata = JSON.parse(JSON.stringify(response.data))

          $(document).ready( function () {
            $('#tbl-guru').DataTable({
              ordering: false,
              lengthMenu: [50, 100, 300, 500],
              searching: true,
              "language": {
                "emptyTable": "Nenhum registro disponível na tabela",
                "info":           "Mostrando _START_ até _END_ de _TOTAL_ registros",
                "infoEmpty":      "Mostrando 0 até 0 de 0 registros",
                "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
                "lengthMenu":     "Mostrar _MENU_ registros",
                "loadingRecords": "Carregando...",
                "search":         "Busca:",
                "zeroRecords":    "Nenhum registro encontrado.",
                "paginate": {
                    "first":      "Primeiro",
                    "last":       "Último",
                    "next":       "Próximo",
                    "previous":   "Anterior"
                }
              },
            });
          });

        })
        .catch(function (error) {
              console.error(error)
        });   
      }


    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
</style>
