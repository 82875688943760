<template>
  <div>
    <div class="row">
      <div class="col-sm-3 text-left">
        <h5 class="card-category">Período</h5>
        <select class="form-control" v-model="curr_tipo">
          <option value="1" style="color:black" selected>Mensal</option>
          <option value="2" style="color:black">Semanal</option>
          <option value="3" style="color:black">Diário</option>
        </select>
      </div>
      <div class="col-sm-9">
        <h5 class="card-category text-right" style="margin-bottom: 0px;">Afiliados</h5>
        <br/>
        <div data-toggle="buttons" class="btn-group btn-group-toggle d-flex flex-wrap float-right">
          <label :id="usr" class="btn btn-sm btn-primary btn-simple" v-for="usr in Object.keys(alldata)" @click="curr_user = usr" :class="{'active': usr == curr_user}">
              {{usr == 0 ? 'TODOS' : afiliados[usr]['nome']}}
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
          <div class="table-full-width table-responsive" style="overflow-y: hidden;">
            <table id="tbl-guru" class="display compact">
              <thead>
                <tr>
                   <th class="text-center sm">
                      <small style="font-weight: bold;">Mês</small>
                   </th>  
                    <th v-for="metrica in metrics" class="th-sm text-center">
                      <small style="font-weight: bold;">{{ metrica['nome'] }}</small>
                    </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in newdata" :key="index">
                    <td>{{index}}</td>
                    <td v-for="metrica in metrics">
                      <template v-if="metrica['id'] == 7">
                        <div class="progress position-relative" style="height:1em;">
                          <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" 
                            :style="'width: '+(parseInt(item[1]/item[4]*100))+'%'" :aria-valuenow="10" 
                            aria-valuemin="0" :aria-valuemax="100">
                            <div class="justify-content-center d-flex position-absolute w-100">{{item[4]==0 ? item[4] : parseInt(item[1]/item[4]*100)}}</div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="progress position-relative" style="height:1em;">
                          <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" 
                            :style="'width: '+(item[metrica['id']]/maxdata[metrica['id']])*100+'%'" :aria-valuenow="10" 
                            aria-valuemin="0" :aria-valuemax="maxdata[metrica['id']]">
                            <div class="justify-content-center d-flex position-absolute w-100">{{item[metrica['id']]}}</div>
                          </div>
                        </div>
                      </template>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-3" :class="isRTL ? 'text-right' : 'text-left'">
                <h5 class="card-category">Performance</h5>
                <h2 class="card-title">Mensal</h2>
              </div>
              <div class="col-sm-9">
                <div class="btn-group btn-group-toggle d-flex flex-wrap"
                     :class="isRTL ? 'float-left' : 'float-right'"
                     data-toggle="buttons">
                  <label v-for="option in metrics"
                         :key="option.id"
                         class="btn btn-sm btn-primary btn-simple"
                         :class="{active: bigLineChart.activeIndex === option.id}"
                         :id="option.id">
                    <input type="radio"
                           @click="initBigChart(option.id)"
                           name="options" autocomplete="off"
                           :checked="bigLineChart.activeIndex === option.id">
                    {{option.nome}}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        ref="bigChart"
                        chart-id="big-line-chart"
                        :chart-data="bigLineChart.chartData"
                        :gradient-colors="bigLineChart.gradientColors"
                        :gradient-stops="bigLineChart.gradientStops"
                        :extra-options="bigLineChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import * as chartConfigs from '@/components/Charts/config';
  import TaskList from './Dashboard/TaskList';
  import UserTable from './Dashboard/UserTable';
  import config from '@/config';

  export default {
    components: {
      LineChart,
      BarChart,
      TaskList,
      UserTable
    },
    data() {
      return {
        curr_tipo: 1,
        alldata: {},
        curr_user: 0,
        userdata: {},
        afiliados: {},
        newdata: {},
        maxdata: {},
        metrics: [],
        table_init: true,
        bigLineChart: {
          activeIndex: 0,
          chartData: {
            datasets: [{ }],
            labels: [],
          },
          extraOptions: chartConfigs.purpleChartOptions,
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
          categories: []
        },
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
    },
    watch: {
      curr_user: function(newValue) {
        var vm = this
        this.userdata = this.alldata[newValue]

        this.ordenaDataTable()
        this.initBigChart(1) 
      },
      curr_tipo: function(newValue) {
        let user = this.getUsuario()
        this.buscaDadosAfiliados(newValue, user.id)
      }
    },
    methods: {
      initBigChart(index) {
        let chartData = {
          datasets: [{
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // data: this.bigLineChart.allData[index]
            data: this.userdata[index] ? this.userdata[index].data : []
          }],
          // labels: ['JAN', 'FEV', 'MAR', 'Abr', 'MAi', 'JUN', 'JUL', 'AGo', 'SET', 'OUT', 'NOV', 'DEZ'],
          labels: this.userdata[index] ? this.userdata[index].label : ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
        }
        this.$refs.bigChart.updateGradients(chartData);
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      },
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.AUTH_USER = null
        }
      },
      ordenaDataTable() {
        var vm = this
        vm.newdata = {}
        Object.keys(this.userdata).forEach(function(metrica, i) {
          vm.maxdata[metrica] = 0;
          // for (let j = vm.userdata[metrica]['label'].length-1; j >=0; j--) {
          for (let j=0; j<vm.userdata[metrica]['label'].length; j++) {
            let dia = vm.userdata[metrica]['label'][j];
            let valor = vm.userdata[metrica]['data'][j];
            if (vm.newdata[dia] == null) {
              vm.newdata[dia] = {}
            }
            if (valor > vm.maxdata[metrica]) {
              vm.maxdata[metrica] = valor
            }
            vm.newdata[dia][metrica] = valor
          }
        })
      }, 
      buscaDadosAfiliados(tipo, user_id) {
        let url = 'dashboard/master/'
        switch(tipo) {
          case '2':
            url += 'semanal/'+user_id
            break
          case '3':
            url += 'diario/'+user_id
            break 
          default:
            url += 'mensal/'+user_id
            break
        }
        this.$http
        .get(this.$API + url)
        .then(response => {
          this.alldata = JSON.parse(JSON.stringify(response.data))
          
          //Calcula TODOS como um novo no
          let todos = {}
          let users = Object.keys(this.alldata)
          for (let m_idx in this.metrics) {
            let m = this.metrics[m_idx]['id']
            todos[m] = {'label': [],'data':[]}
            for (let usr_idx in users) {
              let usr = users[usr_idx]
              if (this.alldata[usr][m]) {
                for (let lbl_idx in this.alldata[usr][m]['label']) {
                  let lbl = this.alldata[usr][m]['label'][lbl_idx]
                  if (!todos[m]['label'].includes(lbl)) {
                    todos[m]['label'].push(lbl)
                  }
                  let idx = this.alldata[usr][m]['label'].indexOf(lbl)
                  let valor_passado = todos[m]['data'][idx] ? todos[m]['data'][idx] : 0
                  todos[m]['data'][idx] = valor_passado + this.alldata[usr][m]['data'][idx]
                } 
              }
            } 
          } 
          
          //Adiciona o no TODOS
          this.alldata[0] = todos
          this.userdata = this.alldata[0]
          this.curr_user = 0
          
          this.ordenaDataTable()
          this.initBigChart(1)
          this.init_datatable()
        })
        .catch(function (error) {
              console.error(error)
        });   
      },
      init_datatable() {
        if (!this.table_init) {
          $('#tbl-guru').dataTable().fnDestroy();
          this.table_init = true
        }
        if (this.table_init) { //so deixar fazer uma vez
         $(document).ready( function () {
              $('#tbl-guru').DataTable({
                ordering: false,
                lengthMenu: [30, 50, 100, 500],
                "columnDefs": [
                  { "width": "10%", "targets": 0 },
                  { "width": "9%", "targets": 1 },
                  { "width": "9%", "targets": 2 },
                  { "width": "9%", "targets": 3 },
                  { "width": "9%", "targets": 4 },
                  { "width": "9%", "targets": 5 },
                  { "width": "9%", "targets": 6 },
                  { "width": "9%", "targets": 7 },
                  { "width": "9%", "targets": 8 },
                  { "width": "9%", "targets": 9 },
                  { "width": "9%", "targets": 10 },
                ],
                searching: false,
                "language": {
                  "emptyTable": "Nenhum registro disponível na tabela",
                  "info":           "Mostrando _START_ até _END_ de _TOTAL_ registros",
                  "infoEmpty":      "Mostrando 0 até 0 de 0 registros",
                  "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
                  "lengthMenu":     "Mostrar _MENU_ registros",
                  "loadingRecords": "Carregando...",
                  "search":         "Busca:",
                  "zeroRecords":    "Nenhum registro encontrado.",
                  "paginate": {
                      "first":      "Primeiro",
                      "last":       "Último",
                      "next":       "Próximo",
                      "previous":   "Anterior"
                  }
                },
              });
          });
          this.table_init = false
        } 
      }
    },
    mounted() {
      var vm = this

      this.$http
      .get(this.$API+'metricas')
      .then(response => {
        this.metrics = response.data
      })
      .catch(function (error) {
            console.error(error)
      });   

      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'ar';
        this.$rtl.enableRTL();
      }
      
      let user = this.getUsuario()
      if (user.id) {
        this.$http
        .get(this.$API+'dashboard/master/afiliados/'+user.id)
        .then(response => {
          this.afiliados = response.data
        })
        .catch(function (error) {
              console.error(error)
        });   

        this.buscaDadosAfiliados(1, user.id)

          // $(document).ready( function () {
          //     $('#tbl-guru').DataTable({
          //       ordering: false,
          //       lengthMenu: [30, 50, 100, 500],
          //       "columnDefs": [
          //         { "width": "10%", "targets": 0 },
          //         { "width": "9%", "targets": 1 },
          //         { "width": "9%", "targets": 2 },
          //         { "width": "9%", "targets": 3 },
          //         { "width": "9%", "targets": 4 },
          //         { "width": "9%", "targets": 5 },
          //         { "width": "9%", "targets": 6 },
          //         { "width": "9%", "targets": 7 },
          //         { "width": "9%", "targets": 8 },
          //         { "width": "9%", "targets": 9 },
          //         { "width": "9%", "targets": 10 },
          //       ],
          //       searching: false,
          //       "language": {
          //         "emptyTable": "Nenhum registro disponível na tabela",
          //         "info":           "Mostrando _START_ até _END_ de _TOTAL_ registros",
          //         "infoEmpty":      "Mostrando 0 até 0 de 0 registros",
          //         "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
          //         "lengthMenu":     "Mostrar _MENU_ registros",
          //         "loadingRecords": "Carregando...",
          //         "search":         "Busca:",
          //         "zeroRecords":    "Nenhum registro encontrado.",
          //         "paginate": {
          //             "first":      "Primeiro",
          //             "last":       "Último",
          //             "next":       "Próximo",
          //             "previous":   "Anterior"
          //         }
          //       },
          //     });
          // });

        // this.$http
        // .get(this.$API+'dashboard/master/mensal/'+user.id)
        // .then(response => {
        //   this.alldata = JSON.parse(JSON.stringify(response.data))
          
        //   //Calcula TODOS como um novo no
        //   let todos = {}
        //   let users = Object.keys(this.alldata)
        //   for (let m_idx in this.metrics) {
        //     let m = this.metrics[m_idx]['id']
        //     todos[m] = {'label': [],'data':[]}
        //     for (let usr_idx in users) {
        //       let usr = users[usr_idx]
        //       if (this.alldata[usr][m]) {
        //         for (let lbl_idx in this.alldata[usr][m]['label']) {
        //           let lbl = this.alldata[usr][m]['label'][lbl_idx]
        //           if (!todos[m]['label'].includes(lbl)) {
        //             todos[m]['label'].push(lbl)
        //           }
        //           let idx = this.alldata[usr][m]['label'].indexOf(lbl)
        //           let valor_passado = todos[m]['data'][idx] ? todos[m]['data'][idx] : 0
        //           todos[m]['data'][idx] = valor_passado + this.alldata[usr][m]['data'][idx]
        //         } 
        //       }
        //     } 
        //   } 
          
        //   //Adiciona o no TODOS
        //   this.alldata[0] = todos
        //   this.userdata = this.alldata[0]
        //   this.curr_user = 0
          
        //   this.ordenaDataTable()
        //   this.initBigChart(1);
          
        //   $(document).ready( function () {
        //       $('#tbl-guru').DataTable({
        //         ordering: false,
        //         lengthMenu: [30, 50, 100, 500],
        //         "columnDefs": [
        //           { "width": "10%", "targets": 0 },
        //           { "width": "9%", "targets": 1 },
        //           { "width": "9%", "targets": 2 },
        //           { "width": "9%", "targets": 3 },
        //           { "width": "9%", "targets": 4 },
        //           { "width": "9%", "targets": 5 },
        //           { "width": "9%", "targets": 6 },
        //           { "width": "9%", "targets": 7 },
        //           { "width": "9%", "targets": 8 },
        //           { "width": "9%", "targets": 9 },
        //           { "width": "9%", "targets": 10 },
        //         ],
        //         searching: false,
        //         "language": {
        //           "emptyTable": "Nenhum registro disponível na tabela",
        //           "info":           "Mostrando _START_ até _END_ de _TOTAL_ registros",
        //           "infoEmpty":      "Mostrando 0 até 0 de 0 registros",
        //           "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
        //           "lengthMenu":     "Mostrar _MENU_ registros",
        //           "loadingRecords": "Carregando...",
        //           "search":         "Busca:",
        //           "zeroRecords":    "Nenhum registro encontrado.",
        //           "paginate": {
        //               "first":      "Primeiro",
        //               "last":       "Último",
        //               "next":       "Próximo",
        //               "previous":   "Anterior"
        //           }
        //         },
        //       });
        //   });
        // })
        // .catch(function (error) {
        //       console.error(error)
        // });   
      }
    },
    beforeDestroy() {
      if (this.$rtl.isRTL) {
        this.i18n.locale = 'en';
        this.$rtl.disableRTL();
      }
    }
  };
</script>
<style>
</style>
