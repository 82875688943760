<template>
  <div>
    <div class="row">
      <div class="col-12">
          <div class="table-full-width table-responsive" style="overflow-y: hidden; overflow-x: hidden;">
            <table id="tbl-guru" class="display compact">
              <thead>
                <tr>
                   <th class="sm">
                   </th>  
                   <th class="sm text-center">
                      <small style="font-weight: bold;">Mês Passado</small>
                   </th>  
                   <th class="sm text-center">
                      <small style="font-weight: bold;">Mês Atual</small>
                   </th> 
                   <th class="sm text-center">
                      <small style="font-weight: bold;">Semana Passada</small>
                   </th> 
                   <th class="sm text-center">
                      <small style="font-weight: bold;">Semana Atual</small>
                   </th>   
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td class="text-center">
                      CPA
                    </td>
                    <td class="text-center">
                      {{ userdata['CPA_mes_passado'] }}
                    </td>
                    <td class="text-center">
                      {{ userdata['CPA_mes_atual'] }}
                    </td>
                    <td class="text-center">
                      {{ userdata['CPA_semana_passada'] }}
                    </td>
                    <td class="text-center">
                      {{ userdata['CPA_semana_atual'] }}
                    </td>
                </tr>
                <tr >
                    <td class="text-center">
                      GGR
                    </td>
                    <td class="text-center">
                      {{ userdata['GGR_mes_passado'] }}
                    </td>
                    <td class="text-center">
                      {{ userdata['GGR_mes_atual'] }}
                    </td>
                    <td class="text-center">
                      {{ userdata['GGR_semana_passada'] }}
                    </td>
                    <td class="text-center">
                      {{ userdata['GGR_semana_atual'] }}
                    </td>
                </tr>
                <tr stlye="border-color:white">
                    <td class="text-center">
                      <b>TOTAL</b>
                    </td>
                    <td class="text-center">
                      <b>{{ userdata['CPA_mes_passado'] + userdata['GGR_mes_passado'] }}</b>
                    </td>
                    <td class="text-center">
                      <b>{{ userdata['CPA_mes_atual'] + userdata['GGR_mes_atual'] }}</b>
                    </td>
                    <td class="text-center">
                      <b>{{ userdata['CPA_semana_passada'] + userdata['GGR_semana_passada'] }}</b>
                    </td>
                    <td class="text-center">
                      <b>{{ userdata['CPA_semana_atual'] + userdata['GGR_semana_atual'] }}</b>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        userdata: {},
      }
    },
    methods: {
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.AUTH_USER = null
        }
      },
      carregarDados() {
        let user = this.getUsuario()
        if (user && user.id) {
          var url = 'dashboard/comissoes/' + user.id
          console.log(url)
          this.$http
          .get(this.$API+url)
          .then(response => {
            console.log(response)
            this.userdata = JSON.parse(JSON.stringify(response.data))

            $(document).ready( function () {
              $('#tbl-guru').DataTable({
                ordering: false,
                lengthMenu: [10],
                searching: false,
                paging: false,
                "language": {
                  "emptyTable": "Nenhum registro disponível na tabela",
                  "info":           "",
                  "infoEmpty":      "",
                  "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
                  "lengthMenu":     "Mostrar _MENU_ registros",
                  "loadingRecords": "Carregando...",
                  "search":         "Busca:",
                  "zeroRecords":    "Nenhum registro encontrado.",
                  "paginate": {
                      "first":      "Primeiro",
                      "last":       "Último",
                      "next":       "Próximo",
                      "previous":   "Anterior"
                  }
                },
              });
            });

          })
          .catch(function (error) {
                console.error(error)
          });   
        }

      }
    },
    mounted() {
      this.carregarDados()
      // $(document).ready( function () {
      //         $('#tbl-guru').DataTable({
      //           ordering: false,
      //           lengthMenu: [50, 100, 300, 500],
      //           searching: true,
      //           "language": {
      //             "emptyTable": "Nenhum registro disponível na tabela",
      //             "info":           "Mostrando _START_ até _END_ de _TOTAL_ registros",
      //             "infoEmpty":      "Mostrando 0 até 0 de 0 registros",
      //             "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
      //             "lengthMenu":     "Mostrar _MENU_ registros",
      //             "loadingRecords": "Carregando...",
      //             "search":         "Busca:",
      //             "zeroRecords":    "Nenhum registro encontrado.",
      //             "paginate": {
      //                 "first":      "Primeiro",
      //                 "last":       "Último",
      //                 "next":       "Próximo",
      //                 "previous":   "Anterior"
      //             }
      //           },
      //         });
      //       });
    },
  };
</script>

<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
</style>
